import { Controller } from "stimulus"
import { createConsumer } from "@rails/actioncable"
import { courbe_evolution_csat } from '../components/charts/courbe_evolution_csat'

export default class extends Controller {
  static values = { userId: String }
  static consumer = null // Keep track of the consumer instance
  static subscription = null // Propriété statique pour stocker l'abonnement

  connect() {
    if (!this.constructor.consumer) {
      this.constructor.consumer = createConsumer();
    }

    if (!this.constructor.subscription) {
      this.constructor.subscription = this.constructor.consumer.subscriptions.create(
        { channel: "EvolCsatChannel", user_id: this.userIdValue },
        { received: this.handleReceived.bind(this) }
      );
    }

    // Listen for Turbo page transitions
    document.addEventListener("turbo:before-render", this.cleanupSubscription);
  }

  disconnect() {
    this.cleanupSubscription();
    document.removeEventListener("turbo:before-render", this.cleanupSubscription);
  }

  cleanupSubscription = () => {
    if (this.constructor.subscription) {
      this.constructor.consumer.subscriptions.remove(this.constructor.subscription);
      this.constructor.subscription = null;
    }

    // Fully disconnect the consumer if no other subscriptions exist
    if (this.constructor.consumer && this.constructor.consumer.subscriptions.subscriptions.length === 0) {
      this.constructor.consumer.disconnect();
      this.constructor.consumer = null;
    }
  };

  handleReceived(data) {
    const evolDiv = document.getElementById("evol_csat_chart_dgq") || document.getElementById("evol_csat_graph");
    if (evolDiv) {
      evolDiv.innerHTML = data.html;
      courbe_evolution_csat();
    }
  }
}
