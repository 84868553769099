import { Controller } from "stimulus";
import {triggerLoadingDots} from '../components/_loading_dots'
export default class extends Controller {

  connect() {
		this.connectFt(this.element)
		console.log("connect")
  }

	connectFt(context) {
		console.log("connectFt")

		context.querySelectorAll(".card-admin-rest-modified").forEach((parentSousGroup) => {
      parentSousGroup.addEventListener("click", () => this.toggleChildSousGroup(parentSousGroup));
    });

    // this.hideDescendantsIfCountGreatThan8(context); // pas utilisée car elements pas sur la page

    const cardAdminRestoElements = context.querySelectorAll('.card-admin-resto');
    cardAdminRestoElements.forEach(cardAdminResto => {
      cardAdminResto.addEventListener('click', this.togglePanelGroupVisibility.bind(this));
    });
	}


  togglePanelGroupVisibility(event) {
		const target = event.currentTarget;

    // Check if the clicked element has the class 'card-admin-resto' or is a descendant of it
    if (target.classList.contains('card-admin-resto')) {
      const rId = target.getAttribute('data-r-id');

      // Find and remove the 'hidden' class from the specific panel with the matching data-r-id
      const specificPanel = document.querySelector(`.panel-default[data-r-id="${rId}"]`);
      if (specificPanel) {
        specificPanel.classList.toggle('hidden');
      }
    }
  }

  hideDescendantsIfCountGreatThan8(context) {
    const sousGroupes = context.querySelectorAll(".card-admin-rest-modified");

    sousGroupes.forEach(sg => {
        const rootId = sg.dataset.rootId;
        const sgId = sg.dataset.sgId;
        const count = parseInt(sg.getAttribute('data-count'));

        if (count >= 8 && rootId !== sgId) {
            sg.classList.add("hidden");
        }
    });
  }

  toggleChildSousGroup(parentSousGroup, to_hide = null) {
    const sousGroupId = parentSousGroup.getAttribute("data-sg-id");
    const elementsToToggle = document.querySelectorAll(`[data-parent-id="${sousGroupId}"]`);

    // If to_hide is not explicitly provided, determine based on the current visibility of direct children
    if (to_hide === null && elementsToToggle.length > 0) {
      to_hide = !elementsToToggle[0].classList.contains("hidden");
    }

    // Function to recursively hide all descendants
    const hideDescendant = (parentId) => {

      const childElements = document.querySelectorAll(`[data-parent-id="${parentId}"]`);
      childElements.forEach(child => {
        child.classList.add("hidden");

        const childSgId = child.getAttribute("data-sg-id");
        document.querySelectorAll(`.card-admin-resto.sg-${childSgId}`).forEach(restaurant => restaurant.classList.add("hidden"));


        // Recursively hide the descendants
        hideDescendant(childSgId);
      });
    };

    elementsToToggle.forEach((element) => {

      if (to_hide) {
        element.classList.add("hidden");
        hideDescendant(element.getAttribute("data-sg-id")); // Recursively hide all descendants
      } else {
        element.classList.remove("hidden");
        element.classList.add("visible");
      }
    });
  }

	loadChildren(event) {
		const btn = event.currentTarget;
		const controller = this;
		if (!btn.classList.contains("loaded")) {
			const groupId = btn.dataset.groupId
			const sousGroupeId = btn.dataset.sgId
			const sgContainer = btn.closest(".sous-groupe-container").querySelector(".children_sous_groupes")

			sgContainer.innerHTML = `<div class="dots-container hidden">
																<div class="dots"></div>
																<div class="dots"></div>
																<div class="dots"></div>
																<div class="dots"></div>
																<div class="dots"></div>
															</div>`
			triggerLoadingDots(sgContainer.querySelector(".dots-container"))
			$.ajax({
				url: `/groups/${groupId}/sous_groupes/${sousGroupeId}/load_children`,
				type: 'GET',
				success: function(response){
					// add elements from the response to the page (i.e. the newly loaded sous-groupes)
					document.querySelector("body").classList.remove("background-gray");
					sgContainer.innerHTML = response;
					controller.connectFt(sgContainer);
					controller.toggleChildSousGroup(btn, false)
					btn.classList.add("loaded")
				},
				error: function(xhr, status, error){
					// handle error
					console.log("AJAX error: " + status + " : " + error);
					document.querySelector("body").classList.remove("background-gray");
					sgContainer.innerHTML = "<p class='card-admin-resto'>Error loading data. Please try again.</p>";
					controller.connectFt(sgContainer);
					controller.toggleChildSousGroup(btn, false)
					btn.classList.add("loaded")
				}
			});
		}
	}

  clearNavbarCacheBo(event) {
    localStorage.removeItem('navbar_ancestry_loop');
    localStorage.removeItem('navbar_ancestry_loop_states');
  }
}
