import { Controller } from "@hotwired/stimulus";
import { createConsumer } from "@rails/actioncable";
import { changeParentSgCheckbox, navigationSelectionDisplay, updateNavbarStateCache } from "../components/_ajax_sous_groupe_ancestry"
export default class extends Controller {

  static values = { navbarId: Number }
  connect() {
    this.setupActionCable();
  }

  // ouvrir le cable du front au back
  setupActionCable() {
    const subscriptionKey = `navbar-loop-${this.navbarIdValue}`;
    const navBarName = document.querySelector(".group-name-for-js")
    const dateFiltre = document.querySelector(".date-filter-btn.filter-pill")
    const indicatorFilter = document.querySelector(".indicator-filter-btn")
    
    // verifier si il n y a pas de souscription déjà en cours pour créer la variable qui check les souscriptions
    if (!window.navbarLoopSubscription) {
      window.navbarLoopSubscription = {};
    }

    // verfier s'il n y a pas de souscription déjà en cours
    if (!window.navbarLoopSubscription[subscriptionKey]) {
      // si non, créer un nouveau cable (souscription)
      window.navbarLoopSubscription[subscriptionKey] = createConsumer().subscriptions.create(
        { channel: "NavbarLoopChannel", id: this.navbarIdValue },
        { received: (data) => {
          let intervalId = setInterval(this.showLoadedRestaurantCount(data, navBarName), 3000);
          if(data.jobStarted) {
            this.disableElement(dateFiltre);
            this.disableElement(indicatorFilter);
          }
          if(data.html) {
            this.putJSForReceivedData(data, intervalId);
            this.storeReceivedData(data);
            this.enableElement(dateFiltre);
            this.enableElement(indicatorFilter);
            updateNavbarStateCache();
          }
        }}
      );
    }

    this.channel = window.navbarLoopSubscription[subscriptionKey];

  }

  showLoadedRestaurantCount(data, navBarName) {
    if(data.sousGroupeLoaded) {
      let text = navBarName.textContent;
      const match = text.match(/\((\d+)\)/);
      if (match) {
        let count = parseInt(match[1], 10);
        count += 1;
        const newText = text.replace(/\(\d+\)/, `(${count})`);
        navBarName.textContent = newText;
      }
    }
  }

  putJSForReceivedData(data, intervalId) {
    if (data.html) {
      const navbarSousGroupesContent = document.getElementById("navbar_ancestry_loop")
      navbarSousGroupesContent.innerHTML = data.html
      changeParentSgCheckbox();
      navigationSelectionDisplay();
      clearInterval(intervalId);
    }
  }

  storeReceivedData(data) {
    if(data.html) {
      const groupId = document.querySelector(".data-current-group").dataset.group;
      localStorage.setItem('navbar_ancestry_loop', data.html);
      localStorage.setItem('navbar_group_id', groupId);
    }
  }

  putBackDateFilter(data, dateFiltre) {
    if(data.html ) {
      dateFiltre.classList.remove("not-clickable");
    }
  }

  disableElement(element) {
    if(element) {
      element.classList.add("not-clickable")
    }
  }

  enableElement(element) {
    if(element) {
      element.classList.remove("not-clickable")
    }
  }
}
